import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { links } from "../common/constants"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Pathway from "../components/pathway"

import Oil1Jpeg from "../images/products/olaj-1.jpeg"
import Oil3Jpeg from "../images/products/olaj-3.jpeg"
import Oil4Jpeg from "../images/products/olaj-4.jpeg"
import Oil5Jpeg from "../images/products/olaj-5.jpeg"
import Oil6Jpeg from "../images/products/olaj-6.jpeg"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <section className="relative">
      <StaticImage
        className="bottom-0 inset-x-0 w-full h-full lg:mb-0 object-cover absolute"
        src="../images/pages/home/hero.png"
        placeholder="blurred"
        alt="hero image"
        style={{ position: "absolute" }}
      />

      <div className="flex flex-wrap mx-auto relative md:w-10/12">
        <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0 py-10 md:py-20 lg:pb-64">
          <div className="">
            <h2 className="mb-8 text-3xl md:text-5xl font-semibold font-heading text-white uppercase">
              Diventa la tua migliore versione con montebello cbd
            </h2>
            <p className="mb-10 md:mb-20 text-white text-xl md:text-2xl font-bold">
              La nostra forza è la tua salute.
            </p>
            <a
              className="inline-block font-bold font-heading transition duration-200 bg-yellow-600 hover:bg-yellow-800 py-4 text-black rounded-md px-6"
              href={links.products}
            >
              Acquistare ora
            </a>
          </div>
        </div>
      </div>

      <div className="hidden lg:block lg:relative bg-gray-50 pt-20 pb-6">
        <div className="container mx-auto px-4">
          <div className="flex lg:-mt-52 flex-wrap justify-center">
            <div
              className="hidden lg:block lg:relative h-64 w-full lg:w-96 mb-6 lg:mb-0 lg:mr-6 bg-no-repeat bg-cover bg-right rounded-md"
              style={{ backgroundImage: `url(${Oil4Jpeg})` }}
            >
              <Link
                className="absolute inset-0 flex items-end"
                to={links.products}
              >
                <div className="pl-12 pb-12">
                  <h3 className="text-3xl font-bold font-heading text-white text-shadow-lg shadow-black">
                    CBDA+CBD
                  </h3>
                </div>
              </Link>
            </div>
            <div
              className="hidden lg:block lg:relative h-64 w-full lg:w-96 bg-no-repeat bg-cover rounded-md"
              style={{ backgroundImage: `url(${Oil1Jpeg})` }}
            >
              <Link
                className="absolute inset-0 flex items-end"
                to={links.products}
              >
                <div className="pl-12 pb-12">
                  <h3 className="text-3xl font-bold font-heading text-white text-shadow-lg shadow-black">
                    Full Spectrum
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="relative flex flex-col py-6 px-6 w-full h-full bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a className="mr-auto text-3xl font-bold font-heading" href="#">
              <img
                className="h-9"
                src="yofte-assets/logos/yofte-logo.svg"
                alt=""
                width="auto"
              />
            </a>
            <button className="navbar-close">
              <svg
                className="h-2 w-2 text-gray-500 cursor-pointer"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00002 1L1 9.00002M1.00003 1L9.00005 9.00002"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </button>
          </div>
          <div className="flex mb-8 justify-between">
            <a
              className="inline-flex items-center font-semibold font-heading"
              href="#"
            >
              <svg
                className="mr-3"
                width="32"
                height="31"
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <span>Sign In</span>
            </a>
            <div className="flex items-center">
              <a className="mr-10" href="#">
                <svg
                  width="23"
                  height="20"
                  viewBox="0 0 23 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </a>
              <a className="flex items-center" href="#">
                <svg
                  className="mr-3"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <span className="inline-block w-6 h-6 text-center bg-gray-100 rounded-full font-semibold font-heading">
                  3
                </span>
              </a>
            </div>
          </div>
          <input
            className="block mb-10 py-5 px-8 bg-gray-100 rounded-md border-transparent focus:ring-blue-300 focus:border-blue-300 focus:outline-none"
            type="search"
            placeholder="Search"
          />
          <ul className="text-3xl font-bold font-heading">
            <li className="mb-8">
              <a href="#">Category</a>
            </li>
            <li className="mb-8">
              <a href="#">Collection</a>
            </li>
            <li className="mb-8">
              <a href="#">Story</a>
            </li>
            <li>
              <a href="#">Brand</a>
            </li>
          </ul>
        </nav>
      </div>
    </section>

    <Pathway />

    <section className="py-20 overflow-x-hidden">
      <div className="mx-auto px-4 container max-w-7xl">
        <h2 className="text-4xl md:text-5xl font-bold font-heading mb-12">
          Prodotti nuovi e in primo piano
        </h2>
        <div className="flex flex-wrap -mx-3 mb-12">
          <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
            <div
              className="relative mb-6 h-64 w-full bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Oil6Jpeg})` }}
            >
              <span className="absolute inset-0 flex items-end">
                <div className="pl-12 pb-12">
                  <h3 className="text-3xl font-bold font-heading text-white text-shadow-lg shadow-black">
                    Supplemento di cibo
                  </h3>
                </div>
              </span>
            </div>
            <div
              className="relative h-64 w-full bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${Oil3Jpeg})` }}
            >
              <span className="absolute inset-0 flex items-end">
                <div className="pl-12 pb-12">
                  <h3 className="text-3xl font-bold font-heading text-white text-shadow-lg shadow-black">
                    Full Spectrum
                  </h3>
                </div>
              </span>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-3">
            <div
              className="relative inline-block mb-6 h-96 lg:h-full w-full bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Oil5Jpeg})` }}
            >
              <div className="absolute bottom-0 left-0 pb-20 pl-12">
                <span className="text-xl font-heading text-white font-bold text-shadow-lg shadow-black">
                  Nuovo arrivo
                </span>
                <h3 className="mt-3 mb-10 text-3xl font-bold font-heading text-white text-shadow-lg shadow-black">
                  CBDA+CBD
                </h3>
                <Link
                  to={links.products}
                  className="inline-block font-bold font-heading py-4 rounded-md transition duration-200 bg-yellow-600 hover:bg-yellow-800 text-black px-6"
                >
                  Acquistare
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Link
          className="text-white font-bold font-heading uppercase py-4 hover:bg-gray-900 px-6 bg-black"
          to={links.products}
        >
          Tutti i prodotti
        </Link>
      </div>
    </section>
  </Layout>
)

export default IndexPage
